.voice-test-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;

  h1 {
    margin: 1rem;
  }

  .voice-test-form-container {
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  .voice-test-form {
    flex: 1;
    margin: 1rem;
    overflow: auto;

    .form-field {
      margin-bottom: 1rem;

      label {
        display: block;
      }

      input[type="number"], input[type="text"], select, textarea {
        width: 100%;
      }

      textarea {
        height: 25vh;
        resize: none;
      }
    }
  }

  .voice-test-response {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 1rem;
    max-width: 50%;
    
    .voice-test-response-scrollable {
      background-color: lightgray;
      border: 1px solid gray;
      flex: 1;
      font-family: monospace;
      overflow: auto;
      padding: 0.5rem;
    }
  }
}

.voice-test {
  .clear-history-container {
    bottom: unset;
    left: unset;
    right: 16px;
    top: 16px;

    button {
      background-color: lightgray;
    }
  }
}