.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    flex-direction: column;

    .overlay-box-container {
        position: absolute;
        bottom: 0;
        left: 0;

        .overlay-header {
            background-color: #fff;
            width: 100vw;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #D9D9D9;
            -webkit-box-shadow: 0px -2px 8px 0px rgba(0,0,0,0.2);
            -moz-box-shadow: 0px -2px 8px 0px rgba(0,0,0,0.2);
            box-shadow: 0px -2px 8px 0px rgba(0,0,0,0.2);
            
            .spacing-div {
                width: 75px;
                margin-left: 20px;
            }
            h4 {
                padding-top: 5px;
            }
            .close-btn {
                width: 75px;
                border: none;
                background-color: transparent;
                cursor: pointer;
                font-size: 18px;
                color: #999;
                padding: 0;
                margin-right: 20px;
                color: #4898D3;
    
                .close-icon {
                    display: flex;
                    align-items: center;
                    img {
                        padding-left: 10px;
                    }
                    &:hover {
                        color: #333;
                    }
                }
            }
        }
        .overlay-content {
            width: 100vw;
            height: 60vh;
            position: relative;
            background-color: #fff;
            border: 0;
            padding: 20px;
            overflow-y: auto;
        }
    }
}