.landing-container {
  margin: 0 auto;
  max-width: 700px;
  padding: 0 1rem;
  text-align: center;

  > img {
    margin-bottom: 2.5rem;
    margin-top: 4.5rem;
  }

  .button-with-label {
    margin-top: 1.5rem;

    button {
      background-color: transparent;
      background-image: var(--call-button-bg-image);
      border: none;
      margin: 0;
      padding: 0;
    }

    span {
      display: block;
      font-size: 14px;
      margin-top: 0.25rem;
    }
  }

  .menu-btn {
    margin-bottom: 5.5rem !important;

    button {
    background: #4898D3;
    border: none;
    border-radius: 8px;
    color: white;
    display: block;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    margin: 1rem auto 2rem;
    padding: 0;
    width: 312px;
    }
  }

  .eval-list {
    ul {
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: center;

      li {
        margin-bottom: 20px;
        padding: none;

        button {
          width: 350px;
          height: 40px;
          border: 1px solid #4898D3;
          border-radius: 8px;
          background-color: #fff;
          font-weight: 500;
        }
      }
    }
  }



  .customer-container {
    margin-bottom: 1rem;

    h3 {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 0;
      margin-top: 0.5rem;
    }

    img {
      border: 4px solid #4898D3;
      border-radius: 50%;
    }

    span {
      font-size: 18px;
    }
  }
}

@media screen and (orientation: portrait) {
  .landing-container {
    max-width: 500px;
  }
}