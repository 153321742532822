.evaluation-container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;

  > img {
    height: 144px;
    margin: 2rem auto;
    width: 159px;
  }

  a {
    text-decoration: none;

    img {
      padding-left: 10px;
    }
  }

  hr {
    border: 1px solid #4898D3;
    margin: 0;
  }

  .evaluation-scrollable {
    flex: 1;
    margin: 0 auto;
    max-width: 700px;
    overflow-y: auto;
    padding: 1.5rem 1rem;
    width: 100%;
    //white-space: pre-wrap;

    h3 {
      font-size: 2rem;
      font-weight: 700;
      margin: 0 auto 1rem;
      max-width: 700px;
      width: 100%;
    }
  
    h6 {
      color: #4898D3;
      font-weight: 700;
      font-size: 1.1rem;
    }

    .evalDate {
      font-weight: 700;
      margin-bottom: 30px;
    }

    // markdown override
    .wmde-markdown {
      background-color: #FFF;
      color: #000;

      p > strong {
        color: #4898D3;
        font-weight: 700;
        font-size: 1.1rem;
      }

    }
  }
}

@media screen and (orientation: portrait) {
  .evaluation-container {
    h3 {
      max-width: 600px;
    }

    .evaluation-scrollable {
      max-width: 600px;
    }
  }
}