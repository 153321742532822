.summaries-container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;

  > img {
    height: 144px;
    margin: 2rem auto;
    width: 159px;
  }

  a {
    text-decoration: none;

    img {
      padding-left: 10px;
    }
  }

  hr {
    border: 1px solid #4898D3;
    margin: 0;
  }

  .summaries-scrollable {
    flex: 1;
    margin: 0 auto;
    max-width: 700px;
    overflow-y: auto;
    padding: 1.5rem 1rem;
    width: 100%;
    //white-space: pre-wrap;

    h3 {
      font-size: 2rem;
      font-weight: 700;
      margin: 0 auto 1rem;
      max-width: 700px;
      width: 100%;
    }

    button {
      background: #4898D3;
      border: none;
      border-radius: 8px;
      color: white;
      display: block;
      font-size: 14px;
      font-weight: 500;
      height: 40px;
      margin: 1rem auto 0;
      overflow: hidden;
      padding: 0 1rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 312px;
    }

    .wmde-markdown {
      background-color: #FFF;
      color: #000;

      p > strong {
        color: #4898D3;
        font-weight: 700;
        font-size: 1.1rem;
      }
    }
  }

  .refresh {
    align-self: flex-end;
    background-color: transparent;
    background-image: var(--refresh-button-bg-image);
    border: none;
    display: block;
    height: 40px;
    margin: 1rem;
    transition: all 0.25s linear;
    width: 40px;

    &.disabled {
      filter: grayscale(1);
      opacity: 0.25;
    }
  }
}

@media screen and (orientation: portrait) {
  .summaries-container {
    h3 {
      max-width: 600px;
    }

    .summaries-scrollable {
      max-width: 600px;
    }
  }
}