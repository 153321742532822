// We have to manually hide the red text error overlay
iframe#webpack-dev-server-client-overlay {
    display: none;
}

.ErrorBoundary {
    background-color: #2f3032;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    .message {
        margin: 20px;
    }

    .retry-button {
        color: #4488eb;
        border: none;
        background: none;
        margin-top: 10px;
    }
}