.role-play-container {
  display: flex;
  height: 100%;
  overflow-y: auto;
  background-color: #343739;
}

.audio-transcript .role-play-container {
  width: 89%;
}

@media (min-width: 992px) and (orientation: landscape) {
  .role-play .role-play-container {
      flex: 0 0 auto;
      max-width: 44%;
      max-width: calc(100vh - 7rem - 4.3vw - 64px);
  }
}

@media screen and (orientation: portrait) {
  .audio-transcript .role-play-container {
    width: 75%;
  }
}
