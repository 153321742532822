.App {
  background-color: white;
  height: 100%;
  display:flex;
  justify-content: center;

  &.knowledge-assistant,
  &.waveform {
    background-image: var(--knowledge-base-landscape-bg-image);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  &.role-play,
  &.objection,
  &.objection-advance {
    align-items: center;
    background-image: var(--default-bg-image);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-color: #343233;
  }

  &.role-play,
  &.audio-transcript,
  &.objection,
  &.objection-advance {
    background-image: var(--landscape-bg-image);
  }

  &.knowledge-assistant,
  &.audio-transcript,
  &.waveform {
    &::after {
      content: 'Tap to enable audio playback';
      position: fixed;
      bottom: 0;
      padding: 0.5rem 1.5rem;
      background-color: rgba(0,0,0,0.25);
      color: white;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      z-index: 1001;
    }
    &.audio-enabled::after {
      content: none;
    }
  }

  .end-modal-overlay {
    align-items: center;
    background-color: rgba(52, 55, 57, 0.75);
    display: none;
    height: 100%;
    inset: 0;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 1010;

    &.visible {
      display: flex;
    }
  }

  .end-modal {
    background-color: #353739;
    border-radius: 1.25rem;
    width: 308px;

    span {
      color: white;
      width: 100%;
      padding: 3rem 2rem;
    }
    > div {
      border-top: 2px #1a1b1c solid;
      width: 100%;
    }

    button {
      color: #52a2dd;
      background: none;
      border: none;
      flex: 1;
      height: 100%;
      padding: 1rem;
      transition: text-shadow 250ms, font-weight 250ms;

      &:hover {
        text-shadow: 0px 0px 3px rgba(255,255,255, 0.25);
        font-weight: 700;
      }
      &:not(:first-child) {
        border-left: 2px #1a1b1c solid;
      }
    }
  }
}

@media screen and (max-width: 1180px) and (orientation: landscape) {
  .App {
    &.role-play,
    &.audio-transcript,
    &.objection,
    &.objection-advance {
      background-size: 100% auto;
    }
  }
}

@media screen and (orientation: portrait) {
  .App {
    &.role-play,
    &.audio-transcript,
    &.objection,
    &.objection-advance {
      background-image: var(--portrait-bg-image);
    }
  }
}

@media screen and (orientation: portrait) {
  .App {
    &.knowledge-assistant,
    &.waveform {
      background-image: var(--knowledge-base-portrait-bg-image);
    }
  }
}

@media screen and (max-width: 767px) {
  .App {
    &.knowledge-assistant,
    &.waveform {
      background-image: var(--knowledge-base-iphone-bg-image);
    }
  }
}

@media (min-width: 600px) {
  .not-mobile {
    background: none;
    border: none;
    display: inline;
    position: absolute;
    text-decoration: none;
    top: 33px;
    left: 33px;
  }

  .mobile {
    display: none;
  }
}

@media (max-width: 600px) {
  .not-mobile {
    display: none;
  }

  .mobile {
    background: none;
    border: none;
    display: inline;
    position: absolute;
    text-decoration: none;
    top: 10px;
    left: 10px;
  }
}