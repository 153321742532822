.menu-container {
  margin: 0 auto;
  max-width: 700px;
  padding: 0 1rem;
  text-align: center;

  > img {
    margin-bottom: 2.5rem;
    margin-top: 4.5rem;
  }

  button {
    background: #4898D3;
    border: none;
    border-radius: 8px;
    color: white;
    display: block;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    margin: 1rem auto 0;
    padding: 0;
    width: 312px;
  }

  p {
    margin-bottom: 5rem;
  }
}

@media screen and (orientation: portrait) {
  .menu-container {
    max-width: 500px;
  }
}